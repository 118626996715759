<template>
  <div class="edit">
    <div class="header">
      <div class="left">
        <van-icon name="arrow-left" @click="gopath" class="icon" />
      </div>
      <div class="title">个人信息</div>
    </div>
    <div class="editlist">
      <div class="item" @click="showModal('nickName')">
        <div class="key">昵称 <span class="icon">*</span></div>
        <div class="val">
          {{ obj.nickName }}
          <span class="valIcon"><van-icon name="arrow" /></span>
        </div>
      </div>
      <div class="item" @click="showModal('sex')">
        <div class="key">性别</div>
        <div class="val">
          {{ obj.sex == 1 ? "男" : "女" }}
          <span class="valIcon"><van-icon name="arrow" /></span>
        </div>
      </div>
      <div class="item" @click="showModal('mail')">
        <div class="key">邮箱</div>
        <div class="val">
          {{ obj.mail }}
          <span class="valIcon"><van-icon name="arrow" /></span>
        </div>
      </div>
      <div class="item" :class="obj.actualName?'disableClass':''" @click="showModal('actualName',obj.actualName)">
        <div class="key">姓名</div>
        <div class="val">
          {{ obj.actualName }}
          <span class="valIcon"><van-icon name="arrow" /></span>
        </div>
      </div>
      <div class="item" :class="obj.idCard?'disableClass':''" @click="showModal('idCard',obj.idCard)">
        <div class="key">身份证号</div>
        <div class="val">
          {{ obj.idCard }}
          <span class="valIcon"><van-icon name="arrow" /></span>
        </div>
      </div>
    </div>
    <van-dialog
      v-model="showFlag"
      :title="title"
      show-cancel-button
      @confirm="confirm"
      @cancel="cancel"
    >
      <div class="input">
        <van-cell-group v-if="inputVal != 2">
          <van-field v-model="value" :placeholder="placeholderText" />
        </van-cell-group>
        <div v-else>
          <div
            class="btn"
            :class="active == 1 ? 'active' : ''"
            @click="sexChange(1)"
          >
            男
          </div>
          <div
            class="btn"
            :class="active == 2 ? 'active' : ''"
            @click="sexChange(2)"
          >
            女
          </div>
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { user, editUser } from "@/api/user.js";
export default {
  data() {
    return {
      obj: {
        nickName: "", //昵称
        sex: 1, //性别：1、男 2、女
        mail: "", // 电子邮箱
        actualName: "", // 姓名
        idCard: "", //身份证号
      },
      title: "",
      placeholderText: "",
      showFlag: false,
      value: "",
      inputVal: 1,
      active: 1,
    };
  },
  created() {
    this.getUser();
  },
  name: "edituser",
  methods: {
    getUser() {
      user().then((res) => {
        this.obj = res.data.data;
      });
    },
    sexChange(val) {
      this.active = val;
    },
    showModal(val,flag) {
      switch (val) {
        case "nickName":
          this.inputVal = 1;
          this.title = "请输入昵称";
          this.placeholderText = this.obj.nickName;
          break;
        case "sex":
          this.inputVal = 2;
          this.active = this.obj.sex;
          this.title = "请选择性别";
          break;
        case "actualName":
          this.inputVal = 3;
          this.title = "请输入姓名";
          this.placeholderText = this.obj.actualName;
          break;
        case "mail":
          this.inputVal = 4;
          this.title = "请输入邮箱";
          this.placeholderText = this.obj.mail;
          break;
        case "idCard":
          this.inputVal = 5;
          this.title = "请输入身份证";
          this.placeholderText = this.obj.idCard;
          break;
      }
      if(!flag) {
        this.showFlag = true;
      }
    },
    confirm() {
      switch (this.inputVal) {
        case 1:
          this.obj.nickName = this.value;
          break;
        case 2:
          this.obj.sex = this.active;
          break;
        case 3:
          this.obj.actualName = this.value;
          break;
        case 4:
          this.obj.mail = this.value;
          break;
        case 5:
          this.obj.idCard = this.value;
          break;
      }
      editUser(this.obj).then((res) => {
        if (res.data.code == 0) {
          this.$toast("修改成功");
        }
      });
      this.value = "";
      this.placeholderText = "";
      this.title = "";
    },
    // 取消
    cancel() {
      this.value = "";
      this.placeholderText = "";
      this.title = "";
    },
    gopath() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.edit {
  .header {
    padding: 10px 10px;
    display: flex;
    .left {
      width: 10%;
      .icon {
        font-size: 24px;
        font-weight: 550;
        color: #141414;
      }
    }
    .title {
      width: 85%;
      text-align: center;
      font-size: 17px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #141414;
    }
  }
  .editlist {
    padding: 0 14px;
    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px 0;
      border-bottom: 1px solid #dddddd;
      .key {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #141414;
        line-height: 20px;
        .icon {
          color: red;
        }
      }
      .val {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #141414;
        line-height: 20px;
      }
    }
  }
}
/deep/ .van-dialog {
  padding: 14px;
  .btn {
    background: #fff;
    color: #141414;
    width: 80%;
    margin: 0 auto;
    padding: 7px 0;
    text-align: center;
    border: 1px solid #ddd;
    margin-bottom: 10px;
    border-radius: 8px;
  }
  .active {
    background: green;
    border: 1px solid #ddd;
    color: #fff;
  }
}
/deep/ .van-button {
  border-radius: 500px;
  border: 1px solid #ddd;
  margin: 0 5px;
}
/deep/ .van-dialog__confirm {
  background: #5f7dff;
  color: #fff;
}
/deep/ .van-dialog__header {
  font-weight: 600;
  font-size: 16px;
}
/deep/ .van-dialog__content {
  margin: 14px 0;
}
/deep/ .van-cell {
  border: 1px solid #ddd;
  border-radius: 10px; /*no*/
}

.disableClass{
  .val{
    color: #8D8D8D !important;
  }
}
</style>
